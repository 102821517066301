import React, { useContext } from "react"
import { AppContext } from "../context/context"
import PropTypes from "prop-types"
import BgImg from "gatsby-background-image"
import { RichTextElement } from "@kentico/gatsby-kontent-components"
import { Link } from "gatsby"
import * as styles from "./serviceHero.module.scss"
const ServiceHero = ({ banner }) => {
  const { size } = useContext(AppContext)
  const { background, services } = banner
  const { ctaButton, heading, subheading, products } =
    services.value[0].elements
  const { button_text, navigation_item } = ctaButton.value[0].elements
  const {
    elements: {
      page: { value: pageData },
    },
  } = navigation_item.value[0]
  const [page] = pageData
  const {
    elements: {
      slug: { value: ctaUrl },
    },
  } = page
  const { price, price_term } = products.value[0].elements
  const priceTerm = price_term.value[0].name
  const newPrice = price.value.split(".")
  const backgroundOverlay = [
    background.value[0].fluid,
    `linear-gradient(rgba(68,68,68,0.75), rgba(68,68,68,0.95))`,
  ].reverse()

  const getTerm = priceTerm => {
    switch (priceTerm) {
      case "Weekly":
        return "wk"
      case "Bi-weekly":
        return "bw"
      case "Monthly":
        return "mo"
      case "Quarterly":
        return "qtly"
      case "Annually":
        return "yr"
      case "One Time":
        return "service"
      default:
        break
    }
  }

  if (size < 768) {
    return (
      <BgImg fluid={backgroundOverlay} className={styles.hero}>
        <section className={`container ${styles.bannerContent}`}>
          <h1>{heading.value}</h1>
          <RichTextElement value={subheading.value} />
          <div className={styles.homePageBannerLinks}>
            <div>
              <div className={styles.price}>
                <span>$</span>
                <span className={styles.bigNumber}>{newPrice[0]}</span>
                <span>{newPrice[1]}</span>
                <span className="regular">/{getTerm(priceTerm)}</span>
              </div>
            </div>
            {ctaUrl !== "undefined" ? (
              <Link to={ctaUrl} className="primary-button">
                {button_text.value}
              </Link>
            ) : (
              ""
            )}
          </div>
        </section>
      </BgImg>
    )
  } else {
    return (
      <BgImg fluid={background.value[0].fluid} className={styles.hero}>
        <section className="container">
          <div className={styles.bannerContent}>
            <h1>{heading.value}</h1>
            <p>{subheading.value}</p>
            <div className={styles.homePageBannerLinks}>
              <div>
                <div className={styles.price}>
                  <span>$</span>
                  <span className={styles.bigNumber}>{newPrice[0]}</span>
                  <span>{newPrice[1]}</span>
                  <span className="regular">/{getTerm(priceTerm)}</span>
                </div>
              </div>
              {ctaUrl !== "undefined" ? (
                <Link to={ctaUrl} className="primary-button">
                  {button_text.value}
                </Link>
              ) : (
                ""
              )}
            </div>
          </div>
        </section>
      </BgImg>
    )
  }
}

ServiceHero.propTypes = {
  banner: PropTypes.object.isRequired,
  price: PropTypes.string,
}

ServiceHero.defaultProps = {
  banner: {},
  price: "29.00",
}

export default ServiceHero
